const transportbands_obj= [
    {
      id: 0,
      item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 150*6Р*96м, армована тільки текстильним матеріалом',
      itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 150*6Р*96м, армована тільки текстильним матеріалом',
      foto: '/datagoods/transportband.png',
      dimensions: '150*6Р*96м'
    },
    {
      id: 1,
      item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 150*6Р*96м, армована тільки текстильним матеріалом',
      itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 150*6Р*96м, армована тільки текстильним матеріалом',
      foto: '/datagoods/transportband.png',
      dimensions: '150*6Р*96м'
    },
    {
        id: 2,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 150*8Р*96м, армована тільки текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 150*8Р*96м, армована тільки текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '150*8Р*96м'
    },
    {
        id: 3,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 175*4Р*96м, армована тільки текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 175*4Р*96м, армована тільки текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '175*4Р*96м'
    },
    {
        id: 4,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 200*5Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 200*5Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '200*5Р*0/0*96м'
    },
    {
        id: 5,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 250*6Р*0/0*96м,армована текстильним матеріалом(6мм)',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 250*6Р*0/0*96м,армована текстильним матеріалом(6мм)',
        foto: '/datagoods/transportband.png',
        dimensions: '250*6Р*0/0*96м'
    },
    {
        id: 6,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 300*6Р*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 300*6Р*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '300*6Р*96м'
    },
    {
        id: 7,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*3Р*0/0*96м,армована текстильним матеріалом(5мм)',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*3Р*0/0*96м,армована текстильним матеріалом(5мм)',
        foto: '/datagoods/transportband.png',
        dimensions: '400*3Р*0/0*96м'
    },
    {
        id: 8,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*4Р*2/0*96м,армована текстильним матеріалом(6мм)',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*4Р*2/0*96м,армована текстильним матеріалом(6мм)',
        foto: '/datagoods/transportband.png',
        dimensions: '400*4Р*2/0*96м'
    },
    {
        id: 9,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*5Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*5Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '400*5Р*0/0*96м'
    },
    {
        id: 10,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*8Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*8Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '400*8Р*0/0*96м'
    },
    {
        id: 11,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*3Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*3Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*3Р*0/0*96м'
    },
    {
        id: 12,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*4Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*4Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*4Р*0/0*96м'
    },
    {
        id: 13,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*5Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*5Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*5Р*0/0*96м'
    },
    {
        id: 14,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*6Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*6Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*6Р*0/0*96м'
    },
    {
        id: 15,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*8Р*0/0*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 500*8Р*0/0*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*8Р*0/0*96м'
    },
    {
        id: 16,
        item: 'Стрічка конвеєрна з вулканізованої гуми,БКНЛ-65 600*3Р*2/0*100м,армована текстильним матеріалом(5мм)',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми,БКНЛ-65 600*3Р*2/0*100м,армована текстильним матеріалом(5мм)',
        foto: '/datagoods/transportband.png',
        dimensions: '600*3Р*2/0*100м'
    },
    {
        id: 17,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 400*3Р*2/0* РБ 100м,армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 400*3Р*2/0* РБ 100м,армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '400*3Р*2/0'
    },
    {
        id: 18,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 400*4Р*2/0* Р 100м,армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 400*4Р*2/0* Р 100м,армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '400*4Р*2/0'
    },
    {
        id: 19,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК 100 500*3Р*2/0*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК 100 500*3Р*2/0*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*3Р*2/0*100м'
    },
    {
        id: 20,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 600*3Р*3/1* Р 100м,армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 600*3Р*3/1* Р 100м,армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '600*3Р*3/1* Р 100м'
    },
    {
        id: 21,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 650*3Р*2/0* РБ 100м,армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК-100 650*3Р*2/0* РБ 100м,армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '650*3Р*2/0* РБ 100м'
    },
    {
        id: 22,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*1000*6Р*6/2*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*1000*6Р*6/2*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '1000*6Р*6/2*100м'
    },
    {
        id: 23,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*300*3Р*3/1*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*300*3Р*3/1*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '300*3Р*3/1*100м'
    },
    {
        id: 24,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК400*500*5Р*5/2*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК400*500*5Р*5/2*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*5Р*5/2*100м'
    },
    {
        id: 25,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*500*5Р*5/2*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*500*5Р*5/2*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '500*5Р*5/2*100м'
    },
    {
        id: 26,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*600*3Р*3/1*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*600*3Р*3/1*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '600*3Р*3/1*100м'
    },
    {
        id: 27,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*650*3Р*3/1*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*650*3Р*3/1*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '650*3Р*3/1*100м'
    },
    {
        id: 28,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*800*3Р*3/1*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*800*3Р*3/1*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '800*3Р*3/1*100м'
    },
    {
        id: 29,
        item: 'Стрічка конв,з вулк,гуми ТК200*800*5/2*РБ 100м',
        itemName: 'Стрічка конв,з вулк,гуми ТК200*800*5/2*РБ 100м',
        foto: '/datagoods/transportband.png',
        dimensions: '800*5/2*РБ 100м'
    },
    {
        id: 30,
        item: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*800*5Р*5/2*100м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, ТК200*800*5Р*5/2*100м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '800*5Р*5/2*100м'
    },
    {
        id: 31,
        item: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*6Р*96м, армована текстильним матеріалом',
        itemName: 'Стрічка конвеєрна з вулканізованої гуми, БКНЛ-65 400*6Р*96м, армована текстильним матеріалом',
        foto: '/datagoods/transportband.png',
        dimensions: '400*6Р*96м'
    },

]

export default transportbands_obj