const main_obj = [
    {id:0, item:"Пiдшипники", foto:'./datagoods/allbearings.png'},
    {id:1, item:"Ременi", foto:'./datagoods/belt.png'},
    {id:2, item:"Сальники", foto:'./datagoods/seal.png'},
    {id:3, item:"Транспортерна стрiчка",foto:'/datagoods/transportband.png'},
    {id:4, item:"Стопорнi кiльця", foto:'/datagoods/stoprings.png'},
    {id:5, item:"Гумовi кiльця" ,foto:'/datagoods/gymrings.png'},
    {id:6, item:"Електроди", foto:'/datagoods/electrodes.png'},
    {id:7, item:"Дрiт зварювальний", foto:'/datagoods/weldingwire.png'},
    {id:8, item:"Ланцюги привiднi", foto:'/datagoods/chains.png'},
    {id:9, item:"Редуктори", foto:'/datagoods/reductors.png'},
    

]

export default main_obj;